
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent, IonSpinner, IonIcon, IonButton, modalController, IonButtons, menuController } from "@ionic/vue";
import { add, refresh, menu } from "ionicons/icons";

import { ref, onMounted } from "vue";

import { dateFormat, dateFormatWithMinutes, timeFormat } from "@/services/utils";
import { openToast } from "@/services/toast";

import apiTimesheet from "@/services/timesheet";

import ModalNuovoTimesheet from "@/components/timesheet/ModalNuovoTimesheet.vue";

export default {
    name: "Timesheet",
    components: {
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonPage,
        IonSpinner,
        IonIcon,
        IonButton,
        IonButtons,
    },
    setup() {
        const userID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_user_id;
        const dipendenteID = JSON.parse(localStorage.getItem("userInfo")).dipendenti_id;

        const loading = ref(false);

        /**
         * ! Menu handler
         */
        const openMenu = () => {
            menuController.open("app-menu");
        };

        /**
         * ! Get all timesheets
         */
        const timesheets = ref([]);
        async function loadTimesheets() {
            loading.value = true;
            try {
                const res = await apiTimesheet.getTimesheet(userID);
                if (res.status != 0) {
                    openToast("Errore durante la richiesta dei dati", "toast_danger");
                }
                timesheets.value = res.data;
            } catch (error) {
                //console.error(error);
                openToast("Errore durante la richiesta dei dati", "toast_danger");
            }
            loading.value = false;
        }
        //loadTimesheets();

        /**
         * ! Open modal to create new timesheet
         */
        async function openModal() {
            const modal = await modalController.create({
                component: ModalNuovoTimesheet,
            });
            modal.onDidDismiss().then((detail) => {
                if (detail.data != undefined) {
                    timesheets.value = [detail.data, ...timesheets.value];
                    openToast("Timesheet inserito correttamente", "toast_success");
                }
            });
            return modal.present();
        }

        /**
         * ! Limit project_name length to 40 charactes
         */
        function setShortProject(project_name) {
            if (!project_name) {
                return;
            } else {
                const trimmedString = project_name.length > 45 ? project_name.substring(0, 42) + "..." : project_name;
                return trimmedString;
            }
        }

        onMounted(() => {
            loadTimesheets();
        });

        return {
            loading,
            dateFormat,
            dateFormatWithMinutes,
            timeFormat,
            add,
            refresh,
            openModal,
            openMenu,
            menu,
            setShortProject,
            loadTimesheets,
            timesheets,
        };
    },
};
